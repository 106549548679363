<template>
  <div>
    <navbar :class="{ show: true }" :showLinks="false"></navbar>
    <div v-if="booking" class="flex-container">
      <div class="topics">
      </div>
      <div class="main-text">
        <h3>{{ $t('bookingConfirmed') }}</h3>
        <p>{{ $t('bookingConfirmedText') }}</p>
        <br>
        <div class="payment-info-content">
          <p>{{ $t('guestId') }} {{booking.booking_reference}}</p>
          <p>{{ $t('guestName') }} {{booking.billing.name}}</p>
          <p>{{ $t('guestSurname') }} {{booking.billing.lastname}}</p>
          <p>{{ $t('country') }} {{booking.billing.country}}</p>
        </div>
        <br>
        <p>{{ $t('pleasure') }} {{booking.site.headline}},
          {{ $t('held') }} {{booking.site.congress_city}}, {{booking.site.state}},
          {{ $tc('from', 1) }} {{booking.site.start_at}} {{ $t('to') }} {{booking.site.finish_at}}.
        </p>

        <div class="payment-info text-space">
          <h1>{{ $t('accommodationBooking') }}</h1>
          <br>
            <div class="payment-info-content">
              <div class="row">
                <p class="column">{{ $t('hotel') }}</p>
                <p class="column">{{booking.hotel.name}}, {{booking.hotel.stars}} <span style="font-size:12px; color:#ffb805;">&#9733;</span></p>
              </div>
              <div class="row">
                <p class="column">{{ $t('address') }}</p>
                <p class="column">{{booking.hotel.address}}</p>
              </div>
              <div class="row">
                <p class="column">{{ $t('numberRooms') }}</p>
                <p class="column">{{booking.number_of_rooms}}</p>
              </div>
              <div class="row">
                <p class="column">{{ $tc('room', 2) }}</p>
                <div class="column">
                    <ul class="no-dots">
                     <li v-for="(item, index) in booking.room_occupancy" :key="index" >
                      <p v-if="item[1] === 1">
                        <!-- ({{item.adults}} {{ $tc('adult', 1) }}) -->
                        {{item[0]}} ({{item[1]}} {{ $tc('adult', 1) }})
                      </p>
                      <p v-else>
                        <!-- ({{item.adults}} {{ $tc('adult', 2) }}) -->
                        {{item[0]}} ({{item[1]}} {{ $tc('adult', 2) }})
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <p class="column">{{ $t('boardType') }}</p>
                <p class="column">{{booking.board_type}}</p>
              </div>
              <div class="row">
                <p class="column">{{ $t('checkInDate') }}</p>
                <p class="column">{{booking.check_in}}</p>
              </div>
              <div class="row">
                <p class="column">{{ $t('checkOutDate') }}</p>
                <p class="column">{{booking.check_out}}</p>
              </div>
            </div>
        </div>
        <div class="payment-info text-space">
          <h1>{{ $t('paymentDetails') }}</h1>
          <br>
          <div class="payment-info-content">
            <div class="row">
              <p class="column">{{ $t('receiptNumber') }}</p>
               <p class="column">{{booking.payment_reference}}</p>
            </div>
            <div class="row">
              <p class="column">{{ $t('price') }}</p>
              <p class="column">{{booking.payment_amount}}</p>
            </div>
            <div class="row">
              <p class="column">{{ $t('dateOfPayment') }}</p>
              <p class="column">{{booking.payment_date}}</p>
            </div>
            <div class="row">
              <p class="column">{{ $t('currency') }}</p>
              <p class="column">{{booking.currency}}</p>
            </div>
            <div class="row">
              <p class="column">{{ $t('paymentDescription') }}</p>
              <p class="column">{{booking.card_type}} {{ $t('card') }}, {{booking.card_emisor}}</p>
            </div>
          </div>
          <br>
          <p>{{ $t('paymenttext') }}</p>
        </div>

        <div class="text-space">
          <h1>{{ $t('modificationsAndCancellations') }}</h1>
          <br>
          <p>{{ $t('modificationsText') }}</p>
          <p v-if="booking.cancellation_policies.length">
            <span v-for="(item, index) in booking.cancellation_policies" :key="index">
              <p>{{ $tc('from', 2) }} {{item[0]}} {{ $t('to') }} {{item[1]}}: {{item[2]}} {{item[3]}}.</p>
            </span>
          </p>
          <br>
          <p>{{ $t('modificationsEmail') }} {{booking.site.contact_email}} .</p>
          <br>
          <p v-html="booking.site.general_terms"></p>
          <br>
          <p>{{ $t('refundAfterMeeting') }}</p>
        </div>

        <div class="text-space">
          <h1>{{ $t('invoiceInformation') }}</h1>
          <p>
            {{ $t('invoiceEmail') }}
            {{ $t('freeContact') }}
            {{ $t('lookForward') }} {{booking.site.congress_city}}!
          </p>
        </div>

        <br>
        <br>

        <a @click="goToCongress" target="_blank" rel="noopener noreferrer" class="btn btn-primary">
          {{ $t('goCongress') }}
        </a>

      </div>
    </div>
    <contact-footer :showFooter="true"/>
    <legal-footer :showFooter="true"/>
  </div>
</template>
<script>

import Navbar from '@/components/Navbar'
import ContactFooter from '@/components/landing-page/ContactFooter'
import LegalFooter from '@/components/landing-page/LegalFooter'
import bookingService from "@/services/bookingService";

export default {
  name: "Confirmation",
  components: {
    Navbar,
    ContactFooter,
    LegalFooter,
  },
  data() {
    return {
      reference: null,
      booking: null,
    }
  },
  methods: {
    goToCongress() {
      this.$router.push({name: 'home'})
    }
  },
  mounted() {
    this.reference = this.$route.params.reference

    bookingService.detail(this.reference)
        .then(data => {
          if (!data.is_booked)
            this.$router.push({name: 'booking-error', params: {reference: this.reference}})

          this.booking = data
        })
        .catch(() => this.$router.push('/404'))
  }
}
</script>

<style lang="scss" scoped>
  .rooms-margin {
    padding-left: 10px;
    margin: -48px 0px -135px 180px;line-height: 20px;
  }

  .text-space {
    margin-top: 25px;
  }
  .payment-info-content {
    background: rgba(233, 233, 233, 0.3);
    padding: 10px 10px 10px 10px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
  }
  .column {
    flex: 1 0 50%;
    white-space: nowrap;
    text-align: left;
    box-sizing: border-box;
  }
  .flex-container {
    display: flex;
    justify-content: start;
    align-items: top;
    width: 100vw;
    padding-top: 30px;
    text-align: left;
  }
  ul.no-dots {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

  .main-text {
    width: 50%;
    font-size: 18px;

    h3 {
      font-size: 42px;
    }
  }

  .topics {
    padding-left: 50px;
    width: 30%;
  }

  a.btn.btn-primary {
    line-height: 40px;
    height: 40px;
    color: white;
    display: inline-block;
    background-color: var(--primary-color);
    margin-bottom: 25px;
    margin-top: 30px;

    &:hover {
      background-color: var(--hover-color);
    }
  }

@media (max-width: 768px) {
  .rooms-margin{
    margin-left:145px!important;
    margin-top: -45px
  }

  .links {
    padding-top: 60px;
    padding-left: 0 !important;
    overflow: scroll;

    a {
      font-weight: bold;
      color: #747272;
      font-size: 14px !important;
    }
  }

  .flex-container {
    height: auto;
    width: auto;
    padding-top: 0;
    margin: 0 20px;
    flex-direction: column;

    .topics {
      padding-left: 0;
    }

    .main-text {
      width: 100%;

      h3 {
        font-size: 22px;
      }

      p, ul {
        font-size: 15px;
      }
    }
  }

}
</style>